.template-header {
    max-width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;

    img {
        max-width: 100%;
        position: fixed;
        top: 0px;
        z-index: 1;
        user-select: none;
        -webkit-user-drag: none;
    }
}

.bm-creative-preview-ad-content {
    // padding-top: 50px;
    letter-spacing: initial !important;

    .sticky-ad {
        position: fixed;
        bottom: 0 !important;
        left: 0;
        width: 100%;
        z-index: 1002;
        text-align: center;
        display: flex;
        justify-content: center;
        top: auto;
        background-color: hsla(0, 0%, 100%, .8);
        padding: 20px 0 20px 0;
        box-shadow: 0 0 5px 0 !important;
    }

    img {
        user-select: none;
        -webkit-user-drag: none;
    }

    .template-article {
        background-color: white;
        margin: 0px;
        padding: 2px;

        .headline {
            display: block;
            padding: 10px 8px;
            font-size: 22px;
            font-weight: bold;
            line-height: 26px;
            color: #004db3;
            font-family: system-ui, -apple-system, sans-serif;
        }

        .article-preview-image {
            img {
                position: relative;
                max-width: 100%;
                user-select: none;
                -webkit-user-drag: none;
            }
        }

        .article-preview-description {
            margin: 0;
            padding: 0;

            p {
                margin: 8px 0 4px;
                display: block;
                padding: 0 8px;
                font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.4em;
                color: #000;
                max-height: 131.6px;
                overflow: hidden;
                letter-spacing: normal;
            }
        }

    }
}

#bm-desktop-container {
    position: relative;

    .preview-container.daily {
        width: 964px;
        margin: 0 auto;
        text-align: left;
    }

    #page-container {
        position: relative;
        font-family: Arial,Helvetica,sans-serif;
        #content {
            img {
                user-select: none;
                -webkit-user-drag: none;
            }
            

            .article {
                margin-bottom: 15px;
                font-size: 1.2em;
                &.article-small{
                    h2 {
                        font-size: 18px;
                        margin-bottom: 10px;
                    margin: 5px 0 0;
                    font-weight: bold;
                    color: #004db3;
                    font-family: Arial, Helvetica, sans-serif;
                    }
                    .article-image{
                    img {
                        max-width: 154px !important;
                    }
                }
                    .article-preview-description{
                        p{
                            font-size: 12px;
                            line-height: 1;
                            padding: 0 !important;
                        }
                        
                    }
                }

                h2 {
                    margin-bottom: 10px;
                    margin: 5px 0 0;
                    font-weight: bold;
                    color: #004db3;
                    font-family: Arial, Helvetica, sans-serif;
                }

                .article-image {
                    padding: 5px 0 4px;

                    img {
                        max-width: 100%;
                        margin-right: 0;
                        float: left;
                        margin: 0 8px 5px 0;
                    }
                }

                .article-preview-description {
                    p {
                        padding: 5px 0 4px;
                        font-family: Arial, Helvetica, sans-serif;
                        color: black;
                        font-size: 19px;
                    }
                }
            }

            .billboard-container {
                width: 970px;
                max-height: 250px;
                display: block;
                margin-left: -3px;
                margin-top: 2em;
                margin-bottom: 1em;

                &.h-0 {
                    height: 0px;

                }

                .sticky-container {
                    max-height: 363.987px;
                    position: static;

                    #bm-creative-container {
                        max-width: 970px;
                        max-height: 250px;
                        z-index: 99;
                        position: sticky !important;
                        top: 0;
                    }
                }
            }

            .split {
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border: 0.5px solid #004db3;
                margin-bottom: 15px;

            }

            .alpha {
                width: 636px;
                margin-right: 20px;
                float: left;
                .splitter{
                    .column-split{
                        width: 308px;
                        float: left;
                    }
                    .first-column{
                        margin-right: 20px
                    }
                }
            }

            .beta {
                width: 308px;
                float: left;

                .femail {
                    font-size: 1.2em;
                    margin-bottom: 15px;
                    position: relative;

                    .puff {
                        min-height: 1px;

                        h3 {
                            color: #fff;
                            background-color: #c562a5;
                            font-weight: bold;
                            font-size: 19px;
                            text-transform: uppercase;
                            padding: 4px 5px 2px;
                            margin-bottom: 5px;
                            border-radius: 4px;
                        }
                    }

                    .link-bogr2 {
                        margin: 0;
                        padding: 0;
                        min-height: 1px;

                        li {
                            color: #000;
                            background-color: #e3e3e3;
                            display: block;
                            min-height: 115px;
                            margin-bottom: 5px;

                            img {
                                float: right;
                                width: 154px;
                            }

                            span {
                                display: block !important;
                                position: relative;
                                padding: 5px 5px 5px 7px;
                                width: 155px;
                                font-weight: bold;
                                display: block;
                                color: #004db3;
                                font-size: 13px !important;
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    #sky-left-container {
        left: -307px;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        position: absolute;
        top: 0;
        width: 350px;

        .sticky-container {
            flex-grow: 1;
            position: fixed;

            #bm-creative-container {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                margin-bottom: 320px;
                margin-left: auto !important;
                margin-right: 0 !important;
                display: block;
                margin-top: 0.7em;
            }
        }
    }

    #sky-right-container {
        right: -360px;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        position: absolute;
        top: 0;
        width: 350px;
    }
}


#bb-preview-device-container{
    // background: white;
    overflow: hidden;
}
.bm-creative-preview{
    word-break: normal;
}

._1qweF6 .bb-samsung .bm-creative-preview-ad-content .sticky-ad { position: sticky; }