.taste{
    font-family: Cabin,"Open Sans",Arial,sans-serif;
    color: #393434;
    user-select: none;
    -webkit-user-drag: none;
    line-height: 1.3;
    letter-spacing: normal;
    position: relative;
    font-size: 14px;

    .text-description{ font-weight: 600; text-align: left; }
    .bg{
        &-main{ background-color: #2c63ab; }
        &-white{ background-color: white; }
        &-taste-light{ background-color: #f1f9fc; }
        &-gray{ background-color: #f6f5f3; }
        &-footer{ background-color: #F3F2F0; }
    }
    &.mobile{
        .sticky-ad-mobile{
            position: fixed;
            bottom: 0 !important;
            left: 0;
            width: 100%;
            z-index: 1002;
            text-align: center;
            display: flex;
            justify-content: center;
            top: auto;
            background-color: #EAEBEC;
            padding: 8px 0;
        }
        .new-banner{ padding: 20px 10px; padding-bottom: 0px;  }
    }

    .desktop{
        &-container{
            margin: 0px auto;
            padding: 16px; 
            width: 100%;
            max-width: 1000px;
        }
        &-header{
            img{ max-width: 1100px; }
        }
        &-footer { 
            text-align: center;
            img{ max-width: 1000px; }
            .new-content{ overflow: hidden !important; img{width: 300px;} }
        }
    }
    &-desktop{
        margin: 0;
        width: auto;
    }
    .new{
        &-banner{ margin: 0 auto; text-align: left; color: #393434; }
        &-content{ font-size: 18px; }
    }
    
    .template-image{ width: 100%; }
    .bb-gray{ border-bottom: 1px solid #f1f0f0; }
    .bb-dash-gray{ border-bottom: 2px dashed #e1e1e0; }
    .desktop-size{ max-width: 1000px;}
    .bm-desktop-container{ max-width: 1000px; }
    .billboard-container {
        padding-bottom: 100%;
        height: 100%;
        position: absolute;
        width: 100%;
        .sticky-container{
            height: auto;
            top: 0px;
            background-color: #EAEBEC;
            position: sticky;
            padding: 10px;
            padding-top: 25px;
            z-index: 998;
        }

        &.h-0 {
            height: 0px;
        }
    }

    &-subrecipe {
        padding: 12px;
        background: white;
        display: inline-grid;
        img{ width: 100%; height: auto; }
    }
    .sticky{ position: sticky; }
    #right-container {
        top: 20px;
        width: 300px;
        height: 100%;
        display: flex;
        z-index: 999;
        padding: 12px;
    
        float: right;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}

._5tqaye.bb-preview-desktop, ._5tqaye.bb-preview-desktop div{overflow: unset !important;}

._5tqaye .bb-samsung .bm-creative-preview-ad-content .sticky-ad-mobile { position: sticky; }