.benchmark-container{
    width: 190px;
    height: 37px;
   /*  position: fixed;
    bottom: 20px;
    left: 10px; */
    cursor: pointer;
    background: #365B9C;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.160784);
    border-radius: 4px;

    display: flex;
    //transition: all 0.3s ease-out;
    animation: fadein 0.2s ease-out;

    span{
        font-size: 14px;
        text-align: left;
    }      
    h2{
        font-size: 16px;
    }
}

.benchmark-info{
    /* position: fixed;
    bottom: 80px;
    left: 10px;
    display: none; */
    display: flex;
    //transition: all 0.2s;
    animation: fadein 0.2s ease-out;
    
    hr{
        border: 0.5px solid #707070;
        width: 188px;
    }

    h2{
        margin: 0 !important;
        text-align: left;
        align-self: self-start;
        font-size: 20px;

    }

    span{
        font-size: 12px;
        text-align: left;
        width: 100%;
    }      

    .benchmark-title{
        color: lightgrey;
    }
   
}

.benchmark-icon{
    width: 40px;
    height: 40px;
}

 /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
  }