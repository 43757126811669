.news{
    font-family: "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif;
    color: #292A33;
    user-select: none;
    -webkit-user-drag: none;
    line-height: 1.3;
    letter-spacing: normal;
    position: relative;
    font-size: 14px;

    h4, h5{ margin-bottom: 0px; font-weight: bold;}
    .text{
        &-main{ color: #F46A00; }
        &-important{ color: #C90000; }
        &-description{ color: #606669; }
        &-gray{ color: #e0e1e2; }
        &-title{ font-family: "Eva Bold","Times New Roman",Times,serif;  margin-top: 10px;}
    }
    
    &.mobile{
        .news-subarticle, .main-article{
            background: white;
            padding-left: 16px;
            padding-right: 16px;
        }
        .sticky-ad-mobile{
            position: fixed;
            bottom: 0 !important;
            left: 0;
            width: 100%;
            z-index: 1002;
            text-align: center;
            display: flex;
            justify-content: center;
            top: auto;
            background-color: #EAEBEC;
            padding: 8px 0;
        }
    }

    .desktop{
        &-container{
            margin: 0px auto;
            padding: 16px; 
            width: 100%;
            max-width: 1440px;
        }
        &-header{
            .header-date{
                background: #202223 url("https://www.news.com.au/wp-content/themes/newscorpau-news-dna/dist/images/icons/news-be-on-it-strip.svg") repeat no-repeat 0 100%;
            }
            .col-4{ align-self: center;}
            .header-tag{
                color: #202223;
                position: sticky;
                top: 0;
                span{ padding: 9px 15px; font-size: 15px;}
            }
        }
        &-footer { 
            padding: 5px 16px;
            text-align: center;
            img{ max-width: 1440px; }
        }
    }
    &-desktop{
        margin: 0;
        width: auto;
    }
    .template-image{
        width: 100%;
    }
    .bb-gray{ border-bottom: 1px solid #eaebec; }
    .bb-black{ border-bottom: 1px solid #202223; }
    .desktop-width{
        max-width: 1248px;
    }
    .pro-scroll-news{background-color: white;}
    .main-article .text-important{ margin-right: 10px; }
    .bm-desktop-container{
        max-width: 1440px;
    }
    .billboard-container {
        padding-bottom: 100%;
        height: 100%;
        position: absolute;
        width: 100%;
        .sticky-container{
            height: auto;
            top: 0px;
            background-color: #EAEBEC;
            position: sticky;
            padding: 10px;
            padding-top: 25px;
            z-index: 998;
        }

        &.h-0 {
            height: 0px;
        }
    }

    &-subarticle, .main-article {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eaebec;
    }
    &-subarticle {
        background: white;
        img{ margin-right: 16px; }
        &.mobile img{ max-width: 100px; }
        &.full-row img{ max-width: 236px; }
        &.column{
            img{ width: 100%; height: fit-content;}
        }
    }
    #right-container {
        top: 20px;
        width: 300px;
        height: 100%;
        display: flex;
        position: sticky;
        z-index: 999;
        margin: 10px 0px 50px 15px;
    
        float: right;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}

._4nqepws.bb-preview-desktop, ._4nqepws.bb-preview-desktop div{overflow: unset !important;}

._4nqepws .bb-samsung .bm-creative-preview-ad-content .sticky-ad-mobile { position: sticky; }