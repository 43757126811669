.escape{
    font-family: Quicksand,"Helvetica Neue",Roboto,Arial,sans-serif;
    user-select: none;
    -webkit-user-drag: none;
    letter-spacing: 1.2px !important;
    position: relative;
    font-size: 14px;
    
    &, h2{color: #202223;}
    .text{
        &-description{ font-weight: 600; text-align: left; }
        &-main{ color: #0f6cc7; }
    }
    .bg{
        &-white{ background-color: white; }
        &-gray{ background-color: #727272; }
    }
    &.mobile{
        .sticky-ad-mobile{
            position: fixed;
            bottom: 0 !important;
            left: 0;
            width: 100%;
            z-index: 1002;
            text-align: center;
            display: flex;
            justify-content: center;
            top: auto;
            background-color: #EAEBEC;
            padding: 8px 0;
        }
        .main-article, .subcontent img{
            padding: 24px;
        }
        // .center-ad-mobile{
        //     padding: 0 20px;
        // }
        .traveler-content{
            padding-left: 24px;
            padding-right: 24px;
        }
        .traveler-list{ overflow: hidden; }
    }

    &.desktop{
        .escape-article{
            img{ width: 100%;}
            .card-info{ padding: 0 16px;}
        }
        .main-article{
            padding: 24px 0px;
        }
        .desktop-footer { 
            text-align: center;
            img{ max-width: 1000px; }
        }
    }
    .traveler-content{ 
        overflow: hidden !important; img{ width: 100%; border-radius: 8px;} 
        padding-bottom: 30px;
        .escape-traveler{
            padding: 0;
            padding-right: 16px;
            &:last-child{ padding-right: 0; }
        }
    }
    &-desktop{
        margin: 0;
        width: auto;
    }
    &.proskin-desktop{
        .desktop-footer, .desktop-header, #page-container, .subcontent { padding: 0 24px; }
    }
    .template-image{ width: 100%; }
    .desktop-size{ max-width: 1000px; margin: 0px auto;}
    .bm-desktop-container{ max-width: 1000px; }
    .billboard-container {
        .sticky-container{
            height: auto;
            top: 0px;
            background-color: #EAEBEC;
            padding: 10px;
        }

        &.h-0 {
            height: 0px;
        }
    }

    &-article {
        padding: 0px 0px 35px 0px;
        background: white;
        display: inline-grid;
        img{ width: 100%; height: auto; border-radius: 8px; }
    }
    #right-container {
        top: 20px;
        margin-top: 20px;
        width: 300px;
        height: 100%;
        display: flex;
        z-index: 999;
        padding: 24px 0;
        position: sticky;
    
        float: right;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}

._6eqsce.bb-preview-desktop, ._6eqsce.bb-preview-desktop div{overflow: unset !important;}

._6eqsce .bb-samsung .bm-creative-preview-ad-content .sticky-ad-mobile { position: sticky; }