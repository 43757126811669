.gumtree{
    background-color: #f4f4f4;
    font-family: ProximaNova-Light,Arial,Helvetica,sans-serif;
    position: relative;
    
    img {
        user-select: none;
        -webkit-user-drag: none;
    }
    
    .template-image{
        width: 100%;
    }
    .gumtree-desktop-width{
        max-width: 1240px;
    }
    .subnav-desktop img{width: 65%;}
    .image-header{ max-width: 1440px; }
    .bg{
        &-gumtree-main{ background-color: #2c2134; }
        &-gumtree-secondary{ background-color: #36263b; }
        &-gumtree-dark{ background-color: #162432; }
    }
    .listing{
        display: inline-flex;
        padding: 5px;
    }
    .button-container{
        text-align: center;
    }
    .gumtree-button{
        border: 2px solid #3baa33;
        cursor: default;
        color: #5a5a5a;
        font-size: 15px;
        text-align: center;
        border-radius: 3px;
        &.desktop{ width: 250px; display: inline-block; }
        &.main{ border: none; background-color: #3baa33; color: white;}
    }
    .template-listing {
        padding: 10px;
        justify-content: space-between;
        width: 100%;
        user-select: none;
        color: black;
        img{
            width: 100%;
        }
        .love-icon{ margin-top: 3px; width: 16px; height: 15px; }
        .listing-header{
            display: flex;
            justify-content: center;
            border: 1px solid #ebebeb;
            box-sizing: border-box;
            background-color: #e9e9e9;
            overflow: hidden;
            height: 100px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .listing-body{
            margin-top: 10px;
            h6{ margin: 0; color: #353535;}
            div{
                color: #9A9A9A;
                font-size: 13px;
            }
        }
        i{
            align-self: center;
            color: white;
            stroke: black;
            stroke-width: 2px;
            text-shadow: 0 0 2px gray;
        }
    }
    .desktop-listing {
        .listing-header { height: 180px; }
        .love-icon{ width: 19px; height: 17px;}
        
    }
}
#bm-desktop-container {
    position: relative;

    .preview-container.gumtree {
        width: 1000px;
        margin: 0;
        text-align: left;
        margin-left: 22px;
    }
    &.premium-gumtree{ //if the format is not proskin
        max-width: unset;
        .preview-container.gumtree{margin-left: 0;width: 100%;}
    }


}

#bb-preview-device-container{
    overflow: hidden;
}
.bm-creative-preview{
    word-break: normal;
}
