  .dd-wrapper {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    width: 350px;
    font-size: 14px;
    margin-right: 10px;
  }
  .dd-header {
    &-title{
      font-size: 12px;
    }
    &.disable{
      background-color: #3a4051;
      color: #6B6D76 !important;
    }
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border: 1px solid #6b6d76;     
    cursor: pointer;
    position: relative;
    background-color: transparent;
    padding: 7px 14px;
    &-main{
      background-color: #e83457;
    }
    &-border-main{
      border-color: #e83457;
      background-color: #4d5363;
    }
  }
  .dd-header span {
    margin-right: 20px;
  }
  .angle-down {
    color: #000;
    margin-right: 20px;
  }
  .dd-list {
    z-index: 10;
    position: absolute;
    width: 100%;
    border: 1px solid #6b6d76;
    border-top: none;
    background-color: #6b6d76;
    max-height: 215px;
    overflow: auto;    
    padding: 0 8px;
    margin: 0;
    font-size: 12px;
  }
  .dd-color{
    &-main{ color: #e83457; }
    &-semi-main{ color: #79354e; }
  }
  .cursor-pointer{ cursor: pointer; }
  .dd-list::-webkit-scrollbar {
    width: 7px; /* Total width including `border-width` of scrollbar thumb */
    height: 7px;
    box-shadow: inset 0 0 100px #2E3547;
    // border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.1em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: rgba(202, 203, 211, 0.20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        //border-radius: 10px;
        padding: 10px;
    }
}


  .dd-list-item {
    width: 100%;    
    padding: 4px 4px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;

    .fa-check{
      color: #ffffff
    }
  }

  .dd-list-item.selected {
    color: #fff;
    background-color: #000;
  }
  .dd-list-item:hover {
    color: #fff;
    background-color: #1c2135;
  }
 