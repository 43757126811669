.sevenNews{
    font-family: HeyWow, Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #292A33;
    user-select: none;
    -webkit-user-drag: none;
    line-height: 1.7;
    letter-spacing: normal;
    position: relative;
    .desktop{
        &-container{
            margin: 0px auto;
            width: 100%;
            max-width: 1440px;
        }
        &-header{
            .bg-gray{ background-color: #f1f1f1; padding: 10px 24px;}
            .date{
                font-size: 14px;
                margin-right: 15px;
            }
            .col-4{ align-self: center; padding: 10px;}
            .logo{
                max-width: 4.625rem;
                height: fit-content;
                &-sport{ max-width: 1019px; }
            }
            .header-tag{
                padding-bottom: 4px;
                span{ padding: 9px 15px; font-size: 15px;}
            }
        }
        &-footer{
            line-height: normal;
            img{ max-width: 990px; }
            .bg-red{ background-color: #e6001e; }
            .footer-tag{
                letter-spacing: 0.17px;
                width: 1000px;
                padding-bottom: 7px;
                padding-left: 1.5em;
                span{ color: white; padding: 0px 15px; font-size: 0.6875rem; border-right: 1px solid white; font-weight: 700;}
                span:last-child{ border: none;}
            }
        }
    }
    &-desktop{
        margin: 0;
        width: auto;
    }
    .bottom-shadow{ box-shadow: rgba(183, 182, 182, 0.52) 0px 10px 16px; }
    .template-image, .template-image-desktop{
        width: 100%;
    }
    .main-article u, .text-green{ color: #5fc749; }
    .pro-scroll-sevenNews{background-color: white;}
    .main-article{
        overflow-wrap: break-word;
        word-break: break-word;
        font-size: 1.125rem;
        font-family: HeyWow, Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        .bg-white{
            background-color: white;
            padding: 15px;
            margin: 0px;
        }
        
        u{ margin: 0px 5px; }
        h2{ font-weight: 700; color: #292A33; margin-bottom: 10px; margin-top: 20px;}
        span{ display: block; margin-bottom: 10px;}
        p{ margin-top: 5px; font-size: 14px; color: #737480; border-bottom: 1px solid #7374805d;}
        .sevenNews-tags{
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 0%;
            overflow-x: auto;
            margin-top: 20px;
            span{
                text-decoration: none;
                transition: background 250ms ease 0s, transform 150ms ease 0s;
                position: relative;
                background: rgb(241, 241, 241);
                color: rgb(41, 42, 51);
                font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 0.75rem;
                font-weight: 550;
                margin: 0px 0.625rem 0.625rem 0px;
                padding: 0.3125rem 0.9375rem;
                border-radius: 1.125rem;
            }
        }
    }
    
    .bm-creative-preview-ad-content {
        letter-spacing: initial !important;
    }
    .sub-title{
        position: relative;
        &.desktop{ padding: 15px; padding-left: 3em; }
    }

    .preview-container.sevenNews {
        margin: 0 auto;
        text-align: left;
    }

    #page-container {
        position: relative;

        #content {
            margin-left: 10px;
            .billboard-container {
                width: 970px;
                height: 250px;
                display: block;
                margin-left: -3px;
                margin-top: 2em;
                margin-bottom: 1em;

                &.h-0 {
                    height: 0px;

                }

                .sticky-container {
                    height: 363.987px;
                    position: static;

                    #bm-creative-container {
                        max-width: 970px;
                        max-height: 250px;
                        z-index: 99;
                        position: sticky !important;
                        top: 0;
                    }
                }
            }

        }
    }

    &-subarticle {
        box-shadow: rgba(183, 182, 182, 0.52) 0px 0px 7px;
        background: white;
        margin-bottom: 15px;
        div{
            display: flex;
            padding: 10px;
            font-size: 12px;
            justify-content: space-between;
        h5{color: #292A33 !important; font-weight: 600;}
        img{ max-width: 180px;}
        }
    }
    #right-container {
        top: 0;
        width: 370px;
        margin-right: 15px;
        margin-top: 10px;
        height: 100%;
        display: flex;
        z-index: 999;

        padding-left: 10px;
        float: right;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    
    }
    #left-container {
        top: 10px;
        width: 300px;
        height: 100%;
        display: flex;
        position: sticky;
        z-index: 999;
        margin: 10px 0px 400px 15px;
    
        float: left;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        
    }
}

._3yhsao.bb-preview-desktop, ._3yhsao.bb-preview-desktop div{overflow: unset !important;}
